var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"90%","icon":"mdi-cellphone-dock","color":"primary","title":"Telegram - Rutinas","text":"Resumen de Rutinas"}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.routines,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Filtrar por nombre: "+_vm._s(_vm.search))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","append-icon":"search","placeholder":"Escribe el nombre","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Agregar rutina")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-container',{staticClass:"pa-5"},[_c('v-row',{staticClass:"mb-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',{staticClass:"mt-1"},[_vm._v("Datos generales")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v("Nombre")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Ingresa el nombre"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1),(
                            !_vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_turn_on_products'
                            )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Categoria ")]),_c('v-select',{attrs:{"dense":"","hide-details":"","placeholder":"Seleccione una categoria","outlined":"","items":[
                                { name: 'Todas las categorías', _id: null } ].concat( _vm.categories ),"item-text":"name","item-value":"_id"},model:{value:(_vm.editedItem.category),callback:function ($$v) {_vm.$set(_vm.editedItem, "category", $$v)},expression:"editedItem.category"}})],1)],1):_vm._e(),(
                            !_vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_shut_down_products'
                            ) &&
                              !_vm.editedItem.typeOfPosts.includes(
                                'meta_catalog_turn_on_products'
                              )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Red social ")]),_c('v-select',{attrs:{"clearable":"","dense":"","hide-details":"","placeholder":"Selecciona las redes sociales","outlined":"","items":_vm.bots,"item-text":"fanpageName","item-value":"_id","multiple":""},model:{value:(_vm.editedItem.bots),callback:function ($$v) {_vm.$set(_vm.editedItem, "bots", $$v)},expression:"editedItem.bots"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Tipo de rutina ")]),_c('v-select',{attrs:{"dense":"","hide-details":"","placeholder":"Seleccione una categoria","outlined":"","items":_vm.typeOfPosts,"item-text":"name","item-value":"value","multiple":""},model:{value:(_vm.editedItem.typeOfPosts),callback:function ($$v) {_vm.$set(_vm.editedItem, "typeOfPosts", $$v)},expression:"editedItem.typeOfPosts"}})],1)],1),(
                            _vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_shut_down_products'
                            ) ||
                              _vm.editedItem.typeOfPosts.includes(
                                'meta_catalog_turn_on_products'
                              )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Catálogos ")]),_c('v-select',{attrs:{"clearable":"","dense":"","hide-details":"","placeholder":"Selecciona los catálogos de Meta","outlined":"","items":_vm.metaCatalogs,"item-text":"name","item-value":"catalogId","multiple":"","return-object":""},model:{value:(_vm.editedItem.metaCatalogs),callback:function ($$v) {_vm.$set(_vm.editedItem, "metaCatalogs", $$v)},expression:"editedItem.metaCatalogs"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Grupo de telegram ")]),_c('VSelectWithValidation',{attrs:{"dense":"","hide-details":"","placeholder":"Seleccione un grupo","outlined":"","rules":"required","items":_vm.groups,"itemText":"name","itemValue":"_id"},model:{value:(_vm.editedItem.telegramGroup),callback:function ($$v) {_vm.$set(_vm.editedItem, "telegramGroup", $$v)},expression:"editedItem.telegramGroup"}})],1)],1),(
                            !_vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_turn_on_products'
                            )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Minimo de inventario ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Ingresa el minimo de inventario"},model:{value:(_vm.editedItem.minStock),callback:function ($$v) {_vm.$set(_vm.editedItem, "minStock", $$v)},expression:"editedItem.minStock"}})],1)],1):_vm._e(),(
                            !_vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_turn_on_products'
                            )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Minimo de tallas ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Ingresa el minimo de tallas"},model:{value:(_vm.editedItem.minSize),callback:function ($$v) {_vm.$set(_vm.editedItem, "minSize", $$v)},expression:"editedItem.minSize"}})],1)],1):_vm._e(),(
                            _vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_shut_down_products'
                            ) ||
                              _vm.editedItem.typeOfPosts.includes(
                                'meta_catalog_turn_on_products'
                              )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.editedItem.typeOfPosts.includes( "meta_catalog_shut_down_products" ) ? "¿Borrar tallas únicas?" : "¿Prender tallas únicas?")+" ")]),_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":_vm.editedItem.hasUniqueSize ? 'Sí' : 'No'},model:{value:(_vm.editedItem.hasUniqueSize),callback:function ($$v) {_vm.$set(_vm.editedItem, "hasUniqueSize", $$v)},expression:"editedItem.hasUniqueSize"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Dias de envío ")]),_c('v-select',{attrs:{"dense":"","hide-details":"","placeholder":"Seleccione los dias de envio","outlined":"","multiple":"","chips":"","items":_vm.scheduleDays},model:{value:(_vm.editedItem.scheduleDays),callback:function ($$v) {_vm.$set(_vm.editedItem, "scheduleDays", $$v)},expression:"editedItem.scheduleDays"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Hora de envío ")]),_c('v-menu',{ref:"timeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editedItem.scheduleTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "scheduleTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "scheduleTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('VTextFieldWithValidation',_vm._g(_vm._b({attrs:{"rules":"required","label":"Seleccione la hora de envio","append-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editedItem.scheduleTime),callback:function ($$v) {_vm.$set(_vm.editedItem, "scheduleTime", $$v)},expression:"editedItem.scheduleTime"}},'VTextFieldWithValidation',attrs,false),on))]}}],null,true),model:{value:(_vm.scheduleTimeMenu),callback:function ($$v) {_vm.scheduleTimeMenu=$$v},expression:"scheduleTimeMenu"}},[(_vm.scheduleTimeMenu)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.timeMenu.save(_vm.scheduleTime)}},model:{value:(_vm.scheduleTime),callback:function ($$v) {_vm.scheduleTime=$$v},expression:"scheduleTime"}}):_vm._e()],1)],1)],1),(
                            !_vm.editedItem.typeOfPosts.includes(
                              'meta_catalog_shut_down_products'
                            ) &&
                              !_vm.editedItem.typeOfPosts.includes(
                                'meta_catalog_turn_on_products'
                              )
                          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Cantidad de imagenes ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Ingresa la cantidad de imagenes"},model:{value:(_vm.editedItem.imagesQuantity),callback:function ($$v) {_vm.$set(_vm.editedItem, "imagesQuantity", $$v)},expression:"editedItem.imagesQuantity"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v("Estado")]),_c('v-select',{attrs:{"dense":"","hide-details":"","placeholder":"Seleccione un estado","outlined":"","items":_vm.status},model:{value:(_vm.editedItem.status),callback:function ($$v) {_vm.$set(_vm.editedItem, "status", $$v)},expression:"editedItem.status"}})],1)],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Guardar")])],1)]}}])})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [(_vm.rolPermisos['Edit'])?_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Editar")]):_vm._e(),(_vm.rolPermisos['Delete'])?_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("Eliminar")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v("Aún no cuentas con rutinas")])]},proxy:true},{key:"item.createdAt",fn:function(ref){
                          var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]}},{key:"item.status",fn:function(ref){
                          var item = ref.item;
return [(item.status === 'active')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.routines.length ? _vm.routines.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.routines.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }